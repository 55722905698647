import { PageContextProps } from 'application/contexts/PageContext';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { GetServerSideProps } from 'next';
import { DXPContentPage } from 'pages/_app';
import logger from 'utilities/logger';

const Page: DXPContentPage<PageProps> = (pageProps) => {
	const { page } = pageProps ?? {};
	const pageData = { ...page };
	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...pageData} {...pageProps} />
		</LayoutFeature>
	);
};

type PageProps = {
	content: Content.PageContent;
};

export const getServerSideProps: GetServerSideProps<PageProps> = async ({ params, preview, previewData: untypedPreviewData, res, req }) => {
	const { host, page } = params;
	const url = page ? (page as string[]).join('/') : '';
	const previewData = untypedPreviewData as Models.PreviewData;
	const pageContext = { preview, previewData, host } as Partial<PageContextProps>;
	try {
		const contentPromise = getPageContent({
			url,
			pageContext,
		});

		res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

		return {
			props: {
				content: await contentPromise,
				host: req?.headers?.['x-original-host'] ? req?.headers?.['x-original-host'] : host,
			},
		};
	} catch (error) {
		const { statusCode } = error;
		if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
			return {
				redirect: {
					destination: error.url,
					permanent: statusCode === 301 || statusCode === 307,
				},
			};
		}
		if (statusCode === 404) {
			return {
				notFound: true,
			};
		}

		logger.error(error);
		throw new Error(error);
	}
};

export default Page;
