import dynamic from 'next/dynamic';
import logger from 'utilities/logger';
import { capitalizeFirstLetter } from 'utilities/string';

export const PageTypeFeature: React.FC<Content.Page> = (page) => {
	const production = process.env.NODE_ENV === 'production';
	const pageName = `${capitalizeFirstLetter(page.template)}`;
	const featureName = `${pageName}Feature`;

	const PageTypeTemplate = dynamic<Content.Page>(() => import(`../../PageTypes/${pageName}/${featureName}`).then((module) => module[featureName])); // Server side rendered

	if (!PageTypeTemplate) {
		const message = `PageType [${page.template}] is missing a React Component. Add missing export '${featureName}' from /features`;
		logger.error(message);
		if (!production) {
			return <div>{message}</div>;
		} else {
			return null;
		}
	}

	return <PageTypeTemplate {...page} />;
};
